import Api from '@/axios'

export default {
  getSeacrhList (value, page, sort) {
    return Api().get(`/pages/categories/products?title=${value}&sort=${sort}&page=${page}`)
  },
  searchProducts (filter) {
    return Api().get(`product-code?code=${filter}`)
  },
  suggestProducts (filter) {
    return Api().post('products', filter)
  },
  getSizes (SubcategoryID) {
    return Api().get(`sizes/${SubcategoryID}`)
  }
}
