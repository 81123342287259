<template>
  <div>
    <section class="iq-style1 py-4">
      <b-container>
        <b-row class="text-start">
          <b-col lg="6" md="4" cols="12">
            <curva-title :title="$t('productDetails.findProduct')"></curva-title>
          </b-col>
          <b-col lg="6" md="8" cols="12" class="d-flex justify-content-start gap_1">
            <div class="headerInput m-0 flex-grow-1">
              <input type="text" class="form-control"
                     :placeholder="$t('forms.enterCode')" v-model="searchText"
                     @keyup.enter="getProductByCode">
              <i class="fas fa-search"></i>
            </div>
            <b-button class="px-5 iq-border-radius-5" variant="warning" @click="getProductByCode"> {{$t('search.search')}} </b-button>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <b-container>
      <div v-if="start">
        <div v-if="loading" class="pt-5 d-flex justify-content-center">
          <b-spinner small type="grow"></b-spinner>
        </div>
        <template v-else>
          <template v-if="product && product.colors.length && !showError">
            <b-row class="p-3" >
              <b-col lg="5" class="mb-4">
                <slider-thumbs :images="product.images" />
              </b-col>
              <b-col lg="7">
                <router-link :to="{name: 'productDetails', params: {id:product.id}}">
                  <div class="d-flex align-items-start justify-content-between mb-3">
                    <h4 class="text-warning font-weight-bold">
                      {{ product.name }}
                    </h4>
                  </div>
                </router-link>

                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center justify-content-between gap_1">
                    <router-link :to="{name: 'categories', params: {category: product.category_id}}"><b-badge
                        class="category_badge p-1 pl-4 pr-4 font-size-14 font-weight-light">{{
                        product.category.name }}</b-badge>
                    </router-link>

                    <router-link :to="{name: 'categories', params: {category: product.category_id}, query: {
              sub : product.subcategory_id}}">
                      <b-badge class="category_badge p-1 pl-4 pr-4 font-size-14 font-weight-light">{{
                          product.subcategory.name }}</b-badge>
                    </router-link>
                    <img v-if="product.offer > 0" :src="require('@/assets/images/curva/offer.png')" alt="offer">
                  </div>
                  <span class="arrival-tag" v-if="product.availability==='coming soon'">{{ $t('product.coming') }}</span>
                </div>

                <iq-card class="mt-3 px-3 py-2 iq-style3 d-flex justify-content-between align-items-center gap_1 flex-wrap">
          <span class="text-white  d-flex justify-content-between align-items-center gap_1 font-size-16">
            <span class="text-gray">{{ $t('productDetails.code') }}</span>
            {{ product.id }}
          </span>
                  <router-link v-if="product.brand_id" :to="`/categories/all-categories?brand=${product.brand_id}`">
                    <b-badge class="category_badge px-3 py-2 d-flex gap_1 text-gray font-size-16 font-weight-light"
                             v-if="product.brand.name">{{ $t('productDetails.brand') }}
                      <span class="text-warning">{{ product.brand.name }}</span></b-badge>
                  </router-link>
                  <router-link v-if="product.club" :to="`/categories/all-categories?club=${product.club_id}`">
                    <b-badge class="category_badge px-3 py-2 d-flex gap_1 text-gray font-size-16 font-weight-light"
                             v-if="product.club.name">{{ $t('productDetails.club') }}
                      <span class="text-warning">{{ product.club.name }}</span></b-badge>
                  </router-link>
                  <router-link v-if="product.season_id" :to="`/categories/all-categories?season=${product.season_id}`">
                    <b-badge v-if="product.season_id"
                             class="category_badge px-3 py-2 d-flex gap_1 text-gray font-size-16 font-weight-light">
                      {{ $t('productDetails.season') }}
                      <span class="text-warning"> {{ product.season.name }} </span></b-badge>
                  </router-link>
                </iq-card>

                <section class="mt-4">
                  <ul class="hint-list">
                    <li>
                      <p class="text-justify font-size-16 m-0"> {{ $t('productDetails.show_data')}}</p>
                    </li>
                    <li>
                      <p class="text-justify font-size-16 m-0">{{$t('productDetails.number_refrence')}}</p>
                    </li>
                    <li>
                      <p class="text-justify font-size-16 m-0">
                        {{ $t('productDetails.recommended')}}
                        <router-link :to="{name: 'contact-us'}" class="text-warning"> {{ $t('order.contact_with_branches') }} </router-link>
                      </p>
                    </li>
                    <li>
                      <p class="text-justify font-size-16 m-0">{{ $t('productDetails.hint')}} </p>
                    </li>
                  </ul>

                </section>
              </b-col>
            </b-row>
            <b-row>
              <b-table responsive :items="product.colors" :fields="fields" class="curva-table"
                       tbody-tr-class="curva-table-row">
                <template #cell(image)="data">
                  <!--      {{data.item.product.id}}-->
                  <router-link v-if="data.item.product_id" :to="{name: 'productDetails', params: {id: data.item.product_id}}" class="d-flex align-items-center">
                    <img :src="data.item.image" class="table-img mx-2">
                  </router-link>
                </template>
                <template v-slot:cell(price)="data">
                  {{ data.item.product_size.final_price }} EGP
                </template>
                <template v-slot:cell(branch)="data1">
                  <div class="d-flex  flex-wrap justify-content-start gap_half" style="max-width: 800px">
            <span  class="category_badge d-inline-block px-2 py-1 iq-border-radius-5" v-for="(item , key) in
            data1.item.stock" :key="key">
            {{item.branch.name}} ({{ item.quantity }})
            </span>
                  </div>
                </template>
              </b-table>
              <!--        {{product.prod_stock}}-->
            </b-row>
          </template>
          <div v-else>
            <div class="text-center">
              <p class="text-center mt-5">
                {{ $t('Product Not Found') }}
              </p>
              <b-button class="add_to_cart_button p-2 px-3 mx-auto">
        <span class="font-size-16" @click="$router.push('/categories/all-categories')">
          {{ $t('back to products') }}
        </span>
              </b-button>
            </div>
          </div>
        </template>

      </div>
    </b-container>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
// import axios from 'axios'
import searchServices from '../services/search.services'
export default {
  data () {
    return {
      product: null,
      showError: false,
      start: null,
      searchText: this.$route.query ? this.$route.query.code : '',
      loading: false,
      fields: [{
        key: 'barcode',
        label: this.$t('search.barcode'),
        class: 'text-center'
      },
      {
        key: 'image',
        label: this.$t('productDetails.image'),
        class: 'text-center'
      },
      {
        key: 'size.name',
        label: this.$t('productDetails.size'),
        class: 'text-center'
      },
      {
        key: 'price',
        label: this.$t('cart.unitPrice'),
        class: 'text-center'
      },
      {
        key: 'branch',
        label: this.$t('search.branches'),
        class: 'text-center'
      }]
    }
  },
  watch: {
    searchText (newVal) {
      this.$router.replace({
        query: { ...this.$route.query, code: newVal }
      })
    }
  },
  methods: {
    getProductByCode () {
      this.start = true
      this.loading = true
      this.showError = false
      this.product = null
      searchServices.searchProducts(this.searchText).then((res) => {
        this.product = res.data.data
      }).catch((err) => {
        if (err.response.data) {
          this.loading = false
          this.showError = true
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created () {
    // this.getProductByCode()
  },
  mounted () {
    core.index()
    if (this.searchText) {
      this.getProductByCode()
    }
  }
}
</script>
<style scoped>
.curva-table-branch thead tr th div{
  text-align: initial !important;
}
.product-img-find {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
}

.gap_half {
  gap: 1.5px
}
.hint-list{
  list-style: circle;
}
</style>
